<template>
  <div>
    <user-list-add-new
      ref="userAdd"
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :role-options="roleOptions"
      @refetch-data="fetchUsers"
    />

    <!-- Filters -->
    <users-list-filters
      :role-filter.sync="roleFilter"
      :role-options="roleOptions"
      @update:role-filter="fetchUsers"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div
              class="d-flex align-items-center justify-content-end"
            >
              <b-form-input
                v-model="searchQuery"
                debounce="500"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                variant="primary"
                @click="isAddNewUserSidebarActive = true"
              >
                <span class="text-nowrap">Add User</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="users"
        responsive
        :fields="tableColumns"
        primary-key="user_id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :busy="loading"
      >
        <template v-slot:table-busy>
          <div class="d-flex justify-content-center my-4">
            <b-spinner
              type="grow"
              style="width: 3rem; height: 3rem;"
              variant="primary"
            />
          </div>
        </template>
        <!-- Column: User -->
        <template v-slot:cell(user)="data">
          <b-media
            vertical-align="center"
            class="align-items-center"
          >
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.user_avatar_url"
                :text="avatarText(`${data.item.user_first_name} ${data.item.user_last_name}`)"
                :variant="`light-${resolveUserRoleVariant(
                  data.item.user_role
                )}`"
                @click="selectUserForEdit(data.item)"
              />
            </template>
            <b-link
              class="font-weight-bold d-block text-nowrap"
              @click="selectUserForEdit(data.item)"
            >
              {{ data.item.user_first_name }} {{ data.item.user_last_name }}
            </b-link>
          </b-media>
        </template>

        <!-- Column: Role -->
        <template v-slot:cell(role)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveUserRoleIcon(data.item.user_role)"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoleVariant(
                data.item.user_role
              )}`"
            />
            <span class="align-text-top text-capitalize">{{
              data.item.user_role
            }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template v-slot:cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(
              data.item.status
            )}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template v-slot:cell(actions)="data">
          <b-button
            variant="outline-danger"
            size="sm"
            @click="confirmDelete(data.item.user_id)"
          >
            Delete
          </b-button>
          <b-button
            v-if="data.item.user_deactivated_at != null"
            class="ml-1"
            variant="outline-secondary"
            size="sm"
            @click="confirmReactivate(data.item.user_id)"
          >
            Reactivate
          </b-button>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span
              class="text-muted"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <icon-modal
      ref="confirm"
      icon="XOctagonIcon"
      icon-classes="text-danger"
      @close="userId = null"
    >
      <p class="text-center">
        Are you sure you would like to delete this user?
      </p>
      <template v-slot:footer>
        <b-button
          variant="default"
          @click="$refs.confirm.close()"
        >
          Go Back
        </b-button>
        <spinner-button
          variant="danger"
          :loading="deleting"
          @click="deleteUser"
        >
          Delete
        </spinner-button>
      </template>
    </icon-modal>
    <confirm-user-reactivation-modal
      ref="reactivate"
      :user-id="userId"
      @close="fetchUsers()"
    />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BPagination,
  BSpinner,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {
  ref, onUnmounted, onMounted, nextTick,
} from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import userStoreService from '@/services/userStoreService'
import RoleTypes from '@/enums/RoleTypes'
import SpinnerButton from '@/layouts/components/SpinnerButton.vue'
import IconModal from '@/layouts/components/IconModal.vue'
import ConfirmUserReactivationModal from '@/views/ConfirmUserReactivationModal.vue'
import UsersListFilters from './UsersListFilters.vue'
import useUsersList from './useUsersList'
import UserListAddNew from './UserListAddNew.vue'

export default {
  components: {
    SpinnerButton,
    UsersListFilters,
    UserListAddNew,
    ConfirmUserReactivationModal,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BPagination,
    BSpinner,
    IconModal,

    vSelect,
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-user'
    const userId = ref(null)
    const deleting = ref(false)
    const userAdd = ref(null)

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreService)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const isAddNewUserSidebarActive = ref(false)

    const roleOptions = RoleTypes.roleTypes()

    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      users,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      loading,
      toast,
      ToastificationContent,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
    } = useUsersList()

    onMounted(() => {
      fetchUsers()
    })

    const selectUserForEdit = user => {
      userAdd.value.setExistingUser(user)

      nextTick(() => {
        isAddNewUserSidebarActive.value = true
      })
    }

    return {
      // Sidebar
      isAddNewUserSidebarActive,
      userAdd,
      selectUserForEdit,

      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      users,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      loading,
      userId,
      toast,
      ToastificationContent,
      deleting,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      roleOptions,

      // Extra Filters
      roleFilter,
    }
  },
  methods: {
    confirmDelete(id) {
      this.userId = id
      this.$refs.confirm.open()
    },
    deleteUser() {
      this.deleting = true
      store.dispatch('app-user/deleteUser', {
        id: this.userId,
      }).then(() => {
        this.fetchUsers()
        this.toast({
          component: this.ToastificationContent,
          props: {
            title: 'Successfully deleted user',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      }).catch(() => {
        this.toast({
          component: this.ToastificationContent,
          props: {
            title: 'Error when deleting user please try again later',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }).finally(() => {
        this.deleting = false
        this.$refs.confirm.close()
      })
    },
    confirmReactivate(userId) {
      this.userId = userId
      this.$refs.reactivate.open()
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}

.warning-icon-size {
  height: 3rem;
  width: 3rem;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
