<template>
  <icon-modal
    ref="reactivateModal"
    icon="AlertTriangleIcon"
    icon-classes="text-warning"
    @close="$emit('close')"
  >
    <p class="text-center">
      Are you sure you would like to reactivate this user?
    </p>

    <template v-slot:footer>
      <b-button
        variant="default"
        @click="close()"
      >
        Go Back
      </b-button>
      <spinner-button
        variant="primary"
        :loading="reactivating"
        @click="reactivateUser"
      >
        Reactivate
      </spinner-button>
    </template>
  </icon-modal>
</template>

<script>
import IconModal from '@/layouts/components/IconModal.vue'
import { BButton } from 'bootstrap-vue'
import SpinnerButton from '@/layouts/components/SpinnerButton.vue'
import { ref } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'ConfirmUserReactivationModal',
  components: {
    IconModal,
    BButton,
    SpinnerButton,
  },
  props: {
    userId: {
      type: Number,
      default: null,
    },
  },
  setup(props) {
    const APP_USER_MODULE_KEY = 'app-user'
    const toast = useToast()

    // Modal
    const reactivateModal = ref(null)

    const open = () => {
      reactivateModal.value.open()
    }

    const close = () => {
      reactivateModal.value.close()
    }

    // Reactivate
    const reactivating = ref(false)

    const reactivateUser = () => {
      reactivating.value = true
      store.dispatch(`${APP_USER_MODULE_KEY}/reactivateUser`, { userId: props.userId })
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Successfully reactivated user',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong when trying to reactivate this user please try again later',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }).finally(() => {
          close()
          reactivating.value = false
        })
    }

    return {
      reactivateModal,
      open,
      close,

      reactivating,
      reactivateUser,
    }
  },
}
</script>
