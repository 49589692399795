<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @shown="initial()"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ userData.user_id === 0 ? 'Add New' : 'Update' }} User
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- User Role -->
          <validation-provider
            #default="validationContext"
            name="User Role"
            rules="required"
          >
            <b-form-group
              label="User Role"
              label-for="user-role"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="userData.user_role"
                placeholder="Select a role"
                :dir="
                  $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                "
                :options="roleOptions"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="user-role"
                :disabled="userData.user_id > 0"
                @input="fetchCustomers"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Admin/Worker Form -->
          <template v-if="showForm()">
            <!-- First Name -->
            <validation-provider
              #default="validationContext"
              name="First Name"
              rules="required"
            >
              <b-form-group
                label="First Name"
                label-for="first-name"
              >
                <b-form-input
                  id="first-name"
                  v-model="userData.user_first_name"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Last Name -->
            <validation-provider
              #default="validationContext"
              name="Last Name"
              rules="required"
            >
              <b-form-group
                label="Last Name"
                label-for="last-name"
              >
                <b-form-input
                  id="last-name"
                  v-model="userData.user_last_name"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Email -->
            <validation-provider
              v-if="userData.user_id === 0"
              #default="validationContext"
              name="Email"
              rules="required|email"
            >
              <b-form-group
                label="Email"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="userData.user_email"
                  :state="getValidationState(validationContext)"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Password -->
            <validation-provider
              v-if="userData.user_id === 0"
              #default="validationContext"
              name="Password"
              :rules="{ required: true, password }"
            >
              <b-form-group
                label="Password"
                label-for="password"
              >
                <b-form-input
                  id="password"
                  v-model="userData.password"
                  :state="getValidationState(validationContext)"
                  type="password"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Password Confirmation -->
            <validation-provider
              v-if="userData.user_id === 0"
              #default="validationContext"
              name="Password Confirmation"
              :rules="{ required: true, is: userData.password }"
            >
              <b-form-group
                label="Confirm Password"
                label-for="password_confirmation"
              >
                <b-form-input
                  id="password_confirmation"
                  v-model="userData.password_confirmation"
                  :state="getValidationState(validationContext)"
                  type="password"
                  trim
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              v-if="userData.user_role === roleTypeWorker"
              #default="validationContext"
              name="location"
              rules="required"
            >
              <b-form-group label="Location">
                <location-picker
                  v-model="userData.user_location_id"
                  :class="getValidationStateNonBootstrap(validationContext)"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- User image -->
            <validation-provider
              #default="validationContext"
              name="Avatar"
              rules="size:5000"
            >

              <b-form-group
                label="User Avatar"
                label-for="avatar"
              >
                <b-form-file
                  v-model="userData.avatar_image"
                  accept=".jpg,.jpeg,.png,.svg"
                  :state="getValidationState(validationContext)"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <template v-if="userData.user_role === roleTypeCustomer">
              <b-form-group
                label="Discounts"
              >
                <v-select
                  v-model="userDiscounts"
                  :options="discounts"
                  label="discount_name"
                  :reduce="val => val.discount_id"
                  multiple
                />
              </b-form-group>

              <b-form-group
                label="Fees"
              >
                <v-select
                  v-model="userFees"
                  :options="fees"
                  label="fee_name"
                  :reduce="val => val.fee_id"
                  :multiple="true"
                  :loading="fetchingFees"
                />
              </b-form-group>

              <b-form-group>
                <validation-provider
                  name="Deliveries"
                  :rules="{ required: false }"
                >
                  <b-form-checkbox
                    :checked="+userData.user_can_receive_deliveries === 1"
                    class="mb-1"
                    @input="val => userData.user_can_receive_deliveries = (val ? 1 : 0)"
                  >
                    Enable deliveries?
                  </b-form-checkbox>
                </validation-provider>

                <validation-provider
                  name="tax exempt"
                  :rules="{ required: false }"
                >
                  <b-form-checkbox
                    :checked="+userData.user_is_taxable === 0"
                    @input="val => userData.user_is_taxable = (val ? 0 : 1)"
                  >
                    Tax exempt?
                  </b-form-checkbox>
                </validation-provider>
              </b-form-group>
            </template>
          </template>

          <template v-else-if="showSelector()">
            <validation-provider
              #default="validationContext"
              name="Customer"
              rules="required"
            >
              <b-form-group label="Import Customer">
                <v-select
                  v-model="customerData"
                  placeholder="Select a customer"
                  :loading="loading"
                  :options="customers"
                  label="first_name"
                  class="form-control p-0 border-control"
                  :class="getValidationStateNonBootstrap(validationContext)"
                >
                  <template #option="data">
                    <p class="mb-0">
                      {{ data.first_name }} {{ data.last_name }} ({{ data.company_name }})
                    </p>
                  </template>
                </v-select>

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <b-form-group
              label="Discounts"
            >
              <v-select
                v-model="userDiscounts"
                :options="discounts"
                label="discount_name"
                :reduce="val => val.discount_id"
                :multiple="true"
                :loading="fetchingDiscounts"
              />
            </b-form-group>

            <b-form-group
              label="Fees"
            >
              <v-select
                v-model="userFees"
                :options="fees"
                label="fee_name"
                :reduce="val => val.fee_id"
                :multiple="true"
                :loading="fetchingFees"
              />
            </b-form-group>

            <b-form-group>
              <validation-provider
                name="Deliveries"
                :rules="{ required: false }"
              >
                <b-form-checkbox
                  :checked="+userData.user_can_receive_deliveries === 1"
                  class="mb-1"
                  @input="val => userData.user_can_receive_deliveries = (val ? 1 : 0)"
                >
                  Enable deliveries?
                </b-form-checkbox>
              </validation-provider>

              <validation-provider
                name="tax exempt"
                :rules="{ required: false }"
              >
                <b-form-checkbox
                  :checked="+userData.user_is_taxable === 0"
                  @input="val => userData.user_is_taxable = (val ? 0 : 1)"
                >
                  Tax exempt?
                </b-form-checkbox>
              </validation-provider>
            </b-form-group>
          </template>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <spinner-button
              :loading="saving"
              variant="primary"
              class="mr-2"
              type="submit"
            >{{ showSelector() ? 'Import' : 'Save' }}</spinner-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormFile,
  BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  required, alphaNum, email, is, password, size,
} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import RoleTypes from '@/enums/RoleTypes'
import SpinnerButton from '@/layouts/components/SpinnerButton.vue'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import LocationPicker from '@/layouts/components/LocationPicker.vue'
import discountAndFeesStoreService from '@/services/discountAndFeesStoreService'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormFile,
    BButton,
    BFormCheckbox,
    vSelect,
    SpinnerButton,
    LocationPicker,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      is,
      password,
      size,
      avatar: {},
    }
  },
  setup(props, { emit }) {
    // Store
    const DISCOUNT_AND_FEE_STORE_KEY = 'app-discount-fee'
    if (!store.hasModule(DISCOUNT_AND_FEE_STORE_KEY)) store.registerModule(DISCOUNT_AND_FEE_STORE_KEY, discountAndFeesStoreService)
    onUnmounted(() => {
      if (store.hasModule(DISCOUNT_AND_FEE_STORE_KEY)) store.unregisterModule(DISCOUNT_AND_FEE_STORE_KEY)
    })

    const toast = useToast()

    const loading = ref(false)
    const saving = ref(false)

    const customers = ref([])
    const discounts = ref([])
    const fees = ref([])
    const fetchingDiscounts = ref(false)
    const fetchingFees = ref(false)
    const userDiscounts = ref([])
    const userFees = ref([])

    const roleTypeCustomer = RoleTypes.Customer.get()
    const roleTypeWorker = RoleTypes.Worker.get()

    const blankUserData = {
      user_id: 0,
      user_first_name: '',
      user_last_name: '',
      user_email: '',
      password: '',
      password_confirmation: '',
      avatar_image: null,
      user_role: null,
      user_can_receive_deliveries: 0,
      user_is_taxable: 1,
      user_location_id: null,
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const customerData = ref(null)
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
      customerData.value = null
    }

    const finish = () => {
      emit('refetch-data')
      emit('update:is-add-new-user-sidebar-active', false)
      resetuserData()
      saving.value = false
    }

    const showError = error => {
      if (error != null && error.response && error.response.data && error.response.data.error) {
        Object.keys(error.response.data.error.data)
          .forEach(errorKey => {
            error.response.data.error.data[errorKey].forEach(e => {
              toast({
                component: ToastificationContent,
                props: {
                  title: e,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
          })
      } else {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error adding user please try again later',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    }

    const onSubmit = () => {
      saving.value = true
      if (userData.value.user_id === 0) {
        if (userData.value.user_role === RoleTypes.Customer.get()) {
          store.dispatch('app-user/importCustomer', {
            customerData: customerData.value,
            canReceiveDeliveries: userData.value.user_can_receive_deliveries,
            isTaxable: userData.value.user_is_taxable,
            discounts: userDiscounts.value,
            fees: userFees.value,
          })
            .then(() => {
              finish()
            })
            .catch(error => {
              showError(error)
              saving.value = false
            })
        } else {
          store.dispatch('app-user/addUser', userData.value)
            .then(() => {
              finish()
            })
            .catch(error => {
              saving.value = false
              showError(error)
            })
        }
      } else {
        store.dispatch('app-user/updateUser', { data: userData.value, discounts: userDiscounts.value, fees: userFees.value })
          .then(() => {
            finish()
          }).catch(error => {
            saving.value = false
            showError(error)
          })
      }
    }

    const fetchCustomers = () => {
      if (userData.value.user_role === RoleTypes.Customer.get()) {
        loading.value = true
        store.dispatch('app-user/fetchCustomers').then(response => {
          loading.value = false
          customers.value = response.data
        }).catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching customers please try again later',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
          loading.value = false
          console.log(error)
        })
      }
    }
    const fetchDiscounts = () => {
      fetchingDiscounts.value = true
      store.dispatch(`${DISCOUNT_AND_FEE_STORE_KEY}/fetchDiscounts`)
        .then(response => {
          discounts.value = response.data
        }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong fetching discounts please try again later',
              variant: 'danger',
              icon: 'AlertTriangleIcon',
            },
          })
        }).finally(() => { fetchingDiscounts.value = false })
    }
    const fetchFees = () => {
      fetchingFees.value = true
      store.dispatch(`${DISCOUNT_AND_FEE_STORE_KEY}/fetchFees`)
        .then(response => {
          fees.value = response.data
        }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong fetching fees please try again later',
              variant: 'danger',
              icon: 'AlertTriangleIcon',
            },
          })
        }).finally(() => { fetchingFees.value = false })
    }

    const {
      refFormObserver, getValidationState, getValidationStateNonBootstrap, resetForm,
    } = formValidation(resetuserData)

    const showForm = () => userData.value.user_id > 0 || userData.value.user_role === RoleTypes.Admin.get() || userData.value.user_role === RoleTypes.Worker.get()

    const showSelector = () => userData.value.user_role === RoleTypes.Customer.get() && userData.value.user_id === 0

    const setExistingUser = user => {
      userData.value = JSON.parse(JSON.stringify(user))

      if (userData.value.user_role === RoleTypes.Customer.get()) {
        store.dispatch('app-user/discountsAndFeesForUser', { userId: userData.value.user_id })
          .then(response => {
            userDiscounts.value = response.data.discounts.map(d => d.discount_id)
            userFees.value = response.data.fees.map(f => f.fee_id)
          }).catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Something went wrong fetching the users discounts and fees please try again later',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
      }
    }

    // initial
    const initial = () => {
      fetchDiscounts()
      fetchFees()
    }

    return {
      userData,
      customerData,
      onSubmit,
      fetchCustomers,
      showForm,
      showSelector,
      loading,
      saving,
      customers,
      roleTypeCustomer,
      roleTypeWorker,
      discounts,
      userDiscounts,
      fees,
      userFees,

      fetchingFees,
      fetchingDiscounts,

      refFormObserver,
      getValidationState,
      getValidationStateNonBootstrap,
      resetForm,

      setExistingUser,
      initial,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
    .vs__dropdown-menu {
        max-height: 200px !important;
    }
}
</style>

<style lang="scss" scoped>
.border-control::v-deep .vs__dropdown-toggle {
  border: 0;
  border-radius: 0;
}
</style>
