import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchDiscounts() {
      return new Promise((resolve, reject) => {
        axios.get('/discounts')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addDiscount(ctx, request) {
      return new Promise((resolve, reject) => {
        axios.post('/discounts', request)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateDiscount(ctx, { id, request }) {
      return new Promise((resolve, reject) => {
        axios.put(`/discounts/${id}`, request)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteDiscount(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios.delete(`/discounts/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchFees() {
      return new Promise((resolve, reject) => {
        axios.get('/fees')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addFee(ctx, request) {
      return new Promise((resolve, reject) => {
        axios.post('fees', request)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateFee(ctx, { id, request }) {
      return new Promise((resolve, reject) => {
        axios.put(`/fees/${id}`, request)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteFee(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios.delete(`/fees/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
