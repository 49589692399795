import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import RoleTypes from '@/enums/RoleTypes'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'user_id', label: 'Id', sortable: true },
    { key: 'user', label: 'Name', sortable: true },
    { key: 'user_email', label: 'Email', sortable: true },
    { key: 'role', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(25)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const users = ref([])
  const loading = ref(false)

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value
      ? refUserListTable.value.localItems.length
      : 0
    return {
      from:
                perPage.value * (currentPage.value - 1)
                + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const fetchUsers = () => {
    loading.value = true
    store
      .dispatch('app-user/fetchUsers', {
        search: searchQuery.value,
        pageSize: perPage.value,
        page: currentPage.value,
        role: roleFilter.value,
      })
      .then(response => {
        const { data } = response

        users.value = data.data
        totalUsers.value = data.total
        loading.value = false
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })

    watch(
      [
        currentPage,
        perPage,
        searchQuery,
        roleFilter,
      ],
      () => {
        fetchUsers()
      },
    )
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === RoleTypes.Admin.get()) return 'primary'
    if (role === RoleTypes.Worker.get()) return 'warning'
    if (role === RoleTypes.Customer.get()) return 'success'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === RoleTypes.Admin.get()) return 'ShieldIcon'
    if (role === RoleTypes.Worker.get()) return 'ClipboardIcon'
    if (role === RoleTypes.Customer.get()) return 'UserIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    users,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,
    loading,
    toast,
    ToastificationContent,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,

    // Extra Filters
    roleFilter,
  }
}
